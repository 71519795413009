import { useState } from 'react';
import { Checkbox, Input } from 'antd';
import PropTypes from 'prop-types';
import _ from 'lodash';
import produce from 'immer';
import { ApolloConsumer } from '@apollo/client';

import '../../styles/components/tables/header-filters.css';
import { useSearchGraphQlState } from './search-filter';
import { GET_CLIENT_NAMES } from '../../queries/client/client-queries';
import { GET_CLIENT_ORGANIZATIONS } from '../../queries/client/client-organization-queries';

export function ShowDeletedCheckbox({ variables, setVariables }) {
  function handleChangeShowDeleted(e) {
    setVariables(variables => ({ ...variables, includeArchived: e.target.checked ? 'YES' : 'NO' }));
  }

  return (
    <Checkbox checked={variables.includeArchived === 'YES'} onChange={handleChangeShowDeleted}>
      Show Deleted
    </Checkbox>
  );
}

ShowDeletedCheckbox.propTypes = {
  variables: PropTypes.object.isRequired,
  setVariables: PropTypes.func.isRequired
};

export function SearchClientNameDirect({ variables, setVariables }) {
  const [searchText, setSearchText, updateVariables] = useSearchGraphQlState({
    variables,
    setVariables,
    fieldName: 'name'
  });

  let timeout = null;

  function submit(text) {
    let value = text;
    if (typeof text !== 'string' || text === '') {
      value = null;
    }
    updateVariables(value);
  }

  function handleInputChange(e) {
    setSearchText(e.target?.value);
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => submit(e.target?.value), 1200);
  }

  return (
    <Input
      autoFocus
      allowClear
      value={searchText}
      placeholder="Search by client name..."
      onChange={handleInputChange}
    />
  );
}

SearchClientNameDirect.propTypes = {
  variables: PropTypes.object.isRequired,
  setVariables: PropTypes.func.isRequired
};

function SearchClientNameToIdRaw({ variables, setVariables, client }) {
  const [searchText, setSearchText] = useState(null);

  function updateStateWithClient(state, value) {
    if (value) {
      state.filter = {
        ...state.filter,
        clientId: { in: value }
      };
    } else if (state.filter?.clientId) {
      delete state.filter.clientId;
      if (_.isEmpty(state.filter)) {
        delete state.filter;
      }
    }
  }

  function updateVariables(value) {
    setVariables(produce(state => updateStateWithClient(state, value)));
  }

  async function searchForClient(searchText) {
    try {
      const res = await client.query({
        query: GET_CLIENT_NAMES,
        variables: { filter: { name: { includesInsensitive: searchText } } }
      });
      updateVariables(res.data?.clients.edges.map(edge => edge.node.id));
    } catch (error) {
      console.error(error);
    }
  }

  let timeout = null;
  function handleInputChange(e) {
    setSearchText(e.target?.value);
    if (timeout) {
      clearTimeout(timeout);
    }
    setTimeout(() => {
      searchForClient(e.target?.value);
    }, 1200);
  }

  return (
    <Input
      autoFocus
      allowClear
      value={searchText}
      placeholder="Search by client name..."
      onChange={handleInputChange}
    />
  );
}

function SearchClientNameToId(props) {
  return <ApolloConsumer>{client => <SearchClientNameToIdRaw client={client} {...props} />}</ApolloConsumer>;
}

SearchClientNameToId.propTypes = {
  variables: PropTypes.object.isRequired,
  setVariables: PropTypes.func.isRequired
};

export function SearchOrganizationDirect({ variables, setVariables }) {
  const [searchText, setSearchText] = useState(null);

  function updateStateWithClient(state, value) {
    if (value) {
      state.filter = {
        ...state.filter,
        organizationId: { equalTo: Number(value) }
      };
    } else if (state.filter?.organizationId) {
      delete state.filter.organizationId;
      if (_.isEmpty(state.filter)) {
        delete state.filter;
      }
    }
  }

  function updateVariables(value) {
    setVariables(produce(state => updateStateWithClient(state, value)));
  }

  function submit(text) {
    let value = text;
    if (typeof text !== 'string' || text === '') {
      value = null;
    }
    updateVariables(value);
  }

  let timeout = null;
  function handleInputChange(e) {
    setSearchText(e.target?.value);
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => submit(e.target?.value), 1200);
  }

  return (
    <Input
      autoFocus
      allowClear
      value={searchText}
      placeholder="Search by organization id (exact)..."
      onChange={handleInputChange}
    />
  );
}

function SearchOrganizationToIdRaw({ fk, setVariables, client }) {
  const [searchText, setSearchText] = useState(null);

  function updateStateWithClient(state, value) {
    if (value) {
      state.filter = {
        ...state.filter,
        [fk]: { in: value }
      };
    } else if (state.filter?.[fk]) {
      delete state.filter[fk];
      if (_.isEmpty(state.filter)) {
        delete state.filter;
      }
    }
  }

  function updateVariables(value) {
    setVariables(produce(state => updateStateWithClient(state, value)));
  }

  async function searchForClient(searchText) {
    if (!searchText) {
      return updateVariables(null);
    }
    try {
      const res = await client.query({
        query: GET_CLIENT_ORGANIZATIONS,
        variables: { filter: { organizationId: { equalTo: Number(searchText) } } }
      });
      updateVariables(
        res.data?.clientOrganizations.edges.map(edge => {
          if (fk === 'id') {
            return edge.node.client.id;
          } else if (fk === 'clientOrganizationId') {
            return edge.node.id;
          }
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  let timeout = null;
  function handleInputChange(e) {
    setSearchText(e.target?.value);
    if (timeout) {
      clearTimeout(timeout);
    }
    setTimeout(() => {
      searchForClient(e.target?.value);
    }, 1200);
  }

  return (
    <Input
      autoFocus
      allowClear
      value={searchText}
      placeholder="Search by organization id (exact)..."
      onChange={handleInputChange}
    />
  );
}

function SearchOrganizationToId(props) {
  return <ApolloConsumer>{client => <SearchOrganizationToIdRaw client={client} {...props} />}</ApolloConsumer>;
}

SearchClientNameToId.propTypes = {
  variables: PropTypes.object.isRequired,
  setVariables: PropTypes.func.isRequired
};
export default function HeaderFilters({ searchClient, searchOrganization, variables, setVariables }) {
  return (
    <div className="header-filters">
      {searchClient !== 'none' && (
        <div className="header-filters__item">
          <label>Client Name</label>
          {searchClient === 'direct' && <SearchClientNameDirect variables={variables} setVariables={setVariables} />}
          {searchClient === 'id' && <SearchClientNameToId variables={variables} setVariables={setVariables} />}
        </div>
      )}
      {searchOrganization !== 'none' && (
        <div className="header-filters__item">
          <label>Organization Id</label>
          {searchOrganization === 'direct' && (
            <SearchOrganizationDirect variables={variables} setVariables={setVariables} />
          )}
          {searchOrganization !== 'direct' && (
            <SearchOrganizationToId fk={searchOrganization} variables={variables} setVariables={setVariables} />
          )}
        </div>
      )}
      <div className="header-filters__item">
        <ShowDeletedCheckbox variables={variables} setVariables={setVariables} />
      </div>
    </div>
  );
}

HeaderFilters.propTypes = {
  variables: PropTypes.object.isRequired,
  setVariables: PropTypes.func.isRequired,
  searchOrganization: PropTypes.oneOf(['direct', 'id', 'clientOrganizationId', 'none']),
  searchClient: PropTypes.oneOf(['direct', 'id', 'none'])
};

HeaderFilters.defaultProps = {
  searchClient: 'id',
  searchOrganization: 'none'
};
