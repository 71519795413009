import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { Formik, Form } from 'formik';
import { Button } from 'antd';
import moment from 'moment';

import FormikInputGroup from './formik-input-group';
import { InputMoney, DatePicker, InputNumber, Checkbox } from './formik-ant';
import { createBillingMonthlyRateSchema } from '../../yup-schemas';
import { CREATE_BILLING_MONTHLY_RATE, GET_BILLING_MONTHLY_CURRENT_RATES } from '../../queries/billing-queries';
import { setAnyFormErrorsFromGraphQlError } from '../../utils/graphql';
import { FormikClientSelector, FormikOrganizationSelector } from '../searchable-selectors';
import {
  BillingMonthlyDynamicDeliveryRateTypeSelectorFormik,
  BillingMonthlyRateTypeSelectorFormik
} from './billing-monthly-rate-selectors';

const TYPES_REQUIRING_ORGANIZATION = new Set(['ANALYTICS', 'DYNAMIC_DELIVERY', 'SMS_MARKETING_SUBSCRIPTION']);

function BillingMonthlyRateFormRaw(props) {
  const {
    initialValues,
    values: { type, legacy2016, subType, clientId },
    setFieldValue,
    handleSubmit,
    isSubmitting
  } = props;

  useEffect(() => {
    if (legacy2016) {
      setFieldValue('amount', 0, false);
    }
  }, [legacy2016, setFieldValue]);

  return (
    <Form onSubmit={handleSubmit}>
      {!initialValues.clientId && (
        <FormikInputGroup name="clientId" label="Client" component={FormikClientSelector} formikProps={props} />
      )}

      <FormikInputGroup label="Type" component={BillingMonthlyRateTypeSelectorFormik} name="type" formikProps={props} />

      {type === 'DYNAMIC_DELIVERY' && (
        <>
          <FormikInputGroup
            label="Sub Type"
            component={BillingMonthlyDynamicDeliveryRateTypeSelectorFormik}
            name="subType"
            formikProps={props}
          />

          {subType === 'FLAT_RATE' && (
            <FormikInputGroup label="Max Locations" component={InputNumber} name="maxLocations" formikProps={props} />
          )}

          {subType === 'PER_LOCATION' && (
            <FormikInputGroup label="Free Locations" component={InputNumber} name="freeLocations" formikProps={props} />
          )}
        </>
      )}

      {type === 'SAAS' && (
        <FormikInputGroup label="Legacy 2016" component={Checkbox} name="legacy2016" formikProps={props} />
      )}

      {type === 'SMS_MARKETING_SUBSCRIPTION' && (
        <FormikInputGroup
          label="SMS Credit Amount"
          component={InputNumber}
          name="smsCreditAmount"
          formikProps={props}
        />
      )}

      {TYPES_REQUIRING_ORGANIZATION.has(type) && (
        <FormikInputGroup
          name="clientOrganizationId"
          label="Organization"
          component={FormikOrganizationSelector}
          formikProps={props}
          clientId={clientId}
        />
      )}

      {!legacy2016 && (
        <FormikInputGroup label="Monthly Rate (USD)" component={InputMoney} name="amount" formikProps={props} />
      )}

      <FormikInputGroup label="Start Date" component={DatePicker} name="startDate" formikProps={props} />

      <Button type="primary" htmlType="submit" loading={isSubmitting}>
        Create
      </Button>
    </Form>
  );
}

export default function BillingMonthlyRateForm(props) {
  const { clientId, title } = props;
  const [createBillingRate] = useMutation(CREATE_BILLING_MONTHLY_RATE);

  async function handleFormSubmit(values, formikProps) {
    formikProps.setSubmitting(true);
    try {
      await createBillingRate({
        variables: values,
        refetchQueries: [{ query: GET_BILLING_MONTHLY_CURRENT_RATES, variables: { clientId: values.clientId } }]
      });
      props.onSuccess();
      notification.success({
        message: `Monthly billing rate created successfully`
      });
    } catch (err) {
      notification.error({
        message: `Error on creating billing rate: ${err}`
      });
      setAnyFormErrorsFromGraphQlError(err, formikProps);
    } finally {
      formikProps.setSubmitting(false);
    }
  }

  return (
    <>
      {title && <Typography.Title level={3}>Create Billing Rate</Typography.Title>}
      <Formik
        enableReinitialize={true}
        onSubmit={handleFormSubmit}
        validationSchema={createBillingMonthlyRateSchema}
        initialValues={{
          clientId,
          startDate: moment().format('YYYY-MM-DD')
        }}
        component={BillingMonthlyRateFormRaw}
      />
    </>
  );
}

BillingMonthlyRateForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  clientId: PropTypes.number,
  title: PropTypes.bool
};

BillingMonthlyRateForm.defaultProps = {
  title: true,
  clientId: null
};
